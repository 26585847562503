const CalcHomePage = resolve => require(['@/views/calc/CalcHomePage'], resolve)
const AnnuityCalcPage = resolve => require(['@/views/calc/AnnuityCalcPage'], resolve)

const MedicalIndexPage = resolve => require(['@/views/calc/medical/MedicalIndexPage'], resolve)
// 医疗个人账户月入金额
const MedicalInsurancePage = resolve => require(['@/views/calc/medical/month-income/MedicalInsurancePage'], resolve)
const MedicResultPage = resolve => require(['@/views/calc/medical/month-income/MedicResultPage'], resolve)

// 医疗一次性趸交
const MedicalPayInFullPage = resolve => require(['@/views/calc/medical/pay-in-full/MedicalPayInFullPage'], resolve)
const MedicalDateRangePage = resolve => require(['@/views/calc/medical/pay-in-full/MedicalDateRangePage'], resolve)
const MedicalPayResultPage = resolve => require(['@/views/calc/medical/pay-in-full/MedicalPayResultPage'], resolve)

// 生育保险
const MaternityInsurancePage = resolve => require(['@/views/calc/maternity/MaternityInsurancePage'], resolve)
const MaternityResultPage = resolve => require(['@/views/calc/maternity/MaternityResultPage'], resolve)

// 工伤保险
const WorkInjuryInsurancePage = resolve => require(['@/views/calc/work-injury/WorkInjuryInsurancePage'], resolve)
const WorkInjuryResultPage = resolve => require(['@/views/calc/work-injury/WorkInjuryResultPage'], resolve)

// 失业保险
const UnemploymentInsurancePage = resolve => require(['@/views/calc/unemploy/UnemploymentInsurancePage'], resolve)
const UnemployResultPage = resolve => require(['@/views/calc/unemploy/UnemployResultPage'], resolve)

// 单位补缴
const PensionSupplementCompanyPage = resolve => require(['@/views/calc/annuity/company/PensionSupplementCompanyPage'], resolve)
const PensionSupplementCompanyDividePage = resolve => require(['@/views/calc/annuity/company/PensionSupplementCompanyDividePage'], resolve)
const PensionSupplementCompanyResultPage = resolve => require(['@/views/calc/annuity/company/PensionSupplementCompanyResultPage'], resolve)
// const TestCalcPage = resolve => require(['@/views/calc/TestCalcPage'], resolve)

// 个人补缴
const PensionSupplementPersonalPage = resolve => require(['@/views/calc/annuity/personal/PensionSupplementPersonalPage'], resolve)
const PensionSupplementPersonalResultPage = resolve => require(['@/views/calc/annuity/personal/PensionSupplementPersonalResultPage'], resolve)

const PensionSupplementResultDetails = resolve => require(['@/views/calc/PensionSupplementResultDetails'], resolve)

// 基本养老金
const BasicAnnuityCalcPage = resolve => require(['@/views/calc/annuity/basic/BasicAnnuityCalcPage'], resolve)
const BasicAnnuityCalcResultPage = resolve => require(['@/views/calc/annuity/basic/BasicAnnuityCalcResultPage'], resolve)
const BasicAnnuityCalcAdvancedPage = resolve => require(['@/views/calc/annuity/basic/BasicAnnuityCalcAdvancedPage'], resolve)
// const BasicAnnuityAdvancedDetailsPage = resolve => require(['@/views/calc/annuity/basic/BasicAnnuityAdvancedDetailsPage'], resolve)

// 领取在职人员死亡待遇
const AnnuityReturnOnJobDiePage = resolve => require(['@/views/calc/annuity/on-job-die/AnnuityReturnOnJobDiePage'], resolve)
const AnnuityReturnOnJobDieFeePage = resolve => require(['@/views/calc/annuity/on-job-die/AnnuityReturnOnJobDieFeePage'], resolve)
const AnnuityReturnOnJobDieResPage = resolve => require(['@/views/calc/annuity/on-job-die/AnnuityReturnOnJobDieResPage'], resolve)

// 领取退休人员死亡待遇
const AnnuityReturnRetireDiePage = resolve => require(['@/views/calc/annuity/retire-die/AnnuityReturnRetireDiePage'], resolve)
const AnnuityReturnRetireDieResPage = resolve => require(['@/views/calc/annuity/retire-die/AnnuityReturnRetireDieResPage'], resolve)

const TestPage = resolve => require(['@/views/test/TestPage'], resolve)

// 反馈意见
const FeedbackPage = resolve => require(['@/views/calc/FeedbackPage'], resolve)

const routers = [{
  path: '/test-ttt',
  name: 'TestPage',
  component: TestPage
}, {
  path: "/calc",
  name: "CalcHomePage",
  component: CalcHomePage,
}, {
  path: "/annuity-calc",
  name: "AnnuityCalcPage",
  component: AnnuityCalcPage,
}, {
  path: "/medical-index",
  name: "MedicalIndexPage",
  component: MedicalIndexPage,
  meta: {
    title: "医疗保险相关计算器",
  },
}, {
  path: "/medical-insurance",
  name: "MedicalInsurancePage",
  component: MedicalInsurancePage,
  meta: {
    title: "个人帐户月入金额计算器",
  },
}, {
  path: "/medical-pay-in-full",
  name: "MedicalPayInFullPage",
  component: MedicalPayInFullPage,
  meta: {
    title: "一次性趸交计算器",
  },
}, {
  path: "/medical-pay-in-full-date-range",
  name: "MedicalDateRangePage",
  component: MedicalDateRangePage,
  meta: {
    title: "一次性趸交添加间断时间",
  }
},{
  path: "/medical-pay-in-full-result",
  name: "MedicalPayResultPage",
  component: MedicalPayResultPage,
  meta: {
    title: "一次性趸交计算结果",
  }

},{
  path: "/maternity-insurance",
  name: "MaternityInsurancePage",
  component: MaternityInsurancePage,
  meta: {
    title: '生育保险计算器'
  }
}, {
  path: "/work-jury-insurance",
  name: "WorkInjuryInsurancePage",
  component: WorkInjuryInsurancePage,
  meta: {
    title: '工伤保险计算器'
  }
}, {
  path: "/work-injury-result",
  name: "WorkInjuryResultPage",
  component: WorkInjuryResultPage,
  meta: {
    title: '工伤保险计算结果'
  }
}, {
  path: "/unemployment-insurance",
  name: "UnemploymentInsurancePage",
  component: UnemploymentInsurancePage,
  meta: {
    title: '失业保险计算器'
  }
}, {
  path: "/medic-result",
  name: "MedicResultPage",
  component: MedicResultPage,
}, {
  path: "/maternity-result",
  name: "MaternityResultPage",
  component: MaternityResultPage,
},{
  path: "/unemploy-result",
  name: "UnemployResultPage",
  component: UnemployResultPage,
}, {
  path: "/pension-supplement-personal",
  name: "PensionSupplementPersonalPage",
  component: PensionSupplementPersonalPage,
  meta: {
    title: '个人养老金补缴计算器'
  }
}, {
  path: "/pension-supplement-personal-result",
  name: "PensionSupplementPersonalResultPage",
  component: PensionSupplementPersonalResultPage,
}, {
  path: "/pension-supplement-company",
  name: "PensionSupplementCompanyPage",
  component: PensionSupplementCompanyPage,
  meta: {
    title: '单位养老金补缴'
  }
}, {
  path: "/pension-supplement-company-divide",
  name: "PensionSupplementCompanyDividePage",
  component: PensionSupplementCompanyDividePage,
}, {
  path: "/pension-supplement-company-result",
  name: "PensionSupplementCompanyResultPage",
  component: PensionSupplementCompanyResultPage,
}, {
  path: "/pension-supplement-result",
  name: "PensionSupplementResultDetails",
  component: PensionSupplementResultDetails,
}, {
  path: "/basic-annuity-calc",
  name: "BasicAnnuityCalcPage",
  component: BasicAnnuityCalcPage,
  meta: {
    title: "基本养老金计算器",
  },
}, {
  path: "/basic-annuity-result",
  name: "BasicAnnuityCalcResultPage",
  component: BasicAnnuityCalcResultPage,
}, {
  path: "/basic-annuity-advanced",
  name: "BasicAnnuityCalcAdvancedPage",
  component: BasicAnnuityCalcAdvancedPage,
}, {
  path: "/annuity-return-on-job-die",
  name: "AnnuityReturnOnJobDiePage",
  component: AnnuityReturnOnJobDiePage,
  meta: {
    title: '在职人员死亡待遇'
  }
}, {
  path: "/annuity-return-on-job-die-fee",
  name: "AnnuityReturnOnJobDieFeePage",
  component: AnnuityReturnOnJobDieFeePage,
  meta: {
    title: '在职人员死亡添加待遇'
  }
},{
  path: "/annuity-return-on-job-die-res",
  name: "AnnuityReturnOnJobDieResPage",
  component: AnnuityReturnOnJobDieResPage,
  meta: {
    title: '在职人员死亡待遇计算结果'
  }
},{
  path: "/annuity-return-retire-die",
  name: "AnnuityReturnRetireDiePage",
  component: AnnuityReturnRetireDiePage,
  meta: {
    title: '领取待遇人员死亡待遇'
  }
},{
  path: "/annuity-return-retire-die-res",
  name: "AnnuityReturnRetireDieResPage",
  component: AnnuityReturnRetireDieResPage,
  meta: {
    title: '领取待遇人员死亡待遇计算结果'
  }
},{
  path: "/feedback",
  name: "FeedbackPage",
  component: FeedbackPage
}]

export default routers