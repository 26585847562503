
const contract = {
    namespaced: true,
    state: {
        imagePath: [],
        contractId: '',
    },
    mutations: {
        setImageList(state, list) {
            state.imagePath = list
        }
    },
    getters: {
        getImageList: ({imagePath}) => {
            return imagePath
        },
    }
}

export default contract