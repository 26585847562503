const AlbumList = resolve => require(['@/views/album/AlbumtListPage'], resolve)
const Album = resolve => require(['@/views/album/AlbumPage'], resolve)
const Topic = resolve => require(['@/views/topic/TopicPage'], resolve)

const routers = [{
  path: '/albums',
  name: 'AlbumList',
  component: AlbumList,
},{
  path: '/album',
  name: 'Album',
  component: Album,
  meta: { refresh:true }
},{
  path: "/topic",
  name: "Topic",
  component: Topic,
  meta: { refresh:true }
}]

export default routers