const calc = {
  namespaced: true,
  state: {
    data: {
      sex: '',
      age: '',
      retireDate: '',
      firstWorkTime: '',
      paymentYearNum: '',
      averageWage: '',
      advanceds: []
    },
    // personalArrear: [], // 个人欠费
    medicalDateRange: [], // 个人医疗间断时间
    medical: { // 医保相关
      payInFull: { // 个人一次性趸交
        begWorkDate: '', // 参加工作时间
        retireDate: '', // 退休时间
        gender: '男',
        medicalGapRange: [] // 医疗间断时间
      }
    },
    annuity: { // 社保相关
      basic: { // 基本养老金
        sex: '',
        age: '',
        retireDate: '',
        firstWorkTime: '',
        paymentYearNum: '',
        averageWage: '',
        feeList: [] // 缴费记录
      },
      company: { // 单位补缴
        gapList: [], // 单位间断时间
        dividedGapList: [] // 按照年分隔好的间断时间
      },
      returnOnJobDie: { // 在职死亡待遇
        dieDate: '', // 死亡时间
        feeList: [] // 缴费记录
      },
      personal: { //个人欠费
        personalGapList: [], // 个人间断时间
      }
    }
  },
  mutations: {
    setAnnuityPersonal(state, val) {
      const { personalGapList } = val
      if(personalGapList != undefined) {
        state.annuity.personal.personalGapList = personalGapList
      }
    },
    setAnnuityCompany(state, val) {
      const { feeList, dividedGapList } = val

      if (feeList != undefined) {
        const key = 'year'
        feeList.sort(function (a, b) {
          let x = a[key];
          let y = b[key];
          return x < y ? -1 : x > y ? 1 : 0;
        });
        state.annuity.company.feeList = feeList 
      }

      if (dividedGapList != undefined) {
        state.annuity.company.dividedGapList = dividedGapList
      }
    },
    setAnnuityBasic(state, val) {
      const { sex, age, retireDate, firstWorkTime, paymentYearNum, averageWage, feeList} = val
      if (sex != undefined) {
        state.annuity.basic.sex = sex
      }

      if (age != undefined) {
        state.annuity.basic.age = age
      }

      if (retireDate != undefined) {
        state.annuity.basic.retireDate = retireDate
      }

      if (firstWorkTime != undefined) {
        state.annuity.basic.firstWorkTime = firstWorkTime
      }

      if (paymentYearNum != undefined) {
        state.annuity.basic.paymentYearNum = paymentYearNum 
      }

      if (feeList != undefined) {
        const key = 'year'
        feeList.sort(function (a, b) {
          let x = a[key];
          let y = b[key];
          return x < y ? -1 : x > y ? 1 : 0;
        });
        state.annuity.basic.feeList = feeList 
      }

      if (averageWage != undefined) {
        state.annuity.basic.averageWage = averageWage
      }

    },
    setMedicalPayInFull(state, val) {
      const { begWorkDate, retireDate, gender, medicalGapRange } = val
      if (begWorkDate != undefined) {
        state.medical.payInFull.begWorkDate = begWorkDate
      }

      if (gender != undefined) {
        state.medical.payInFull.gender = gender
      }

      if (retireDate != undefined) {
        state.medical.payInFull.retireDate = retireDate
      }

      if (medicalGapRange != undefined) {
        state.medical.payInFull.medicalGapRange = medicalGapRange
      }
    },
    setAnnuityReturnOnJobDie(state, val) {
      const { dieDate, feeList } = val
      if (dieDate != undefined) {
        state.annuity.returnOnJobDie.dieDate = dieDate
      }

      if (feeList != undefined) {
        const key = 'year'
        feeList.sort(function (a, b) {
          let x = a[key];
          let y = b[key];
          return x < y ? -1 : x > y ? 1 : 0;
        });
        state.annuity.returnOnJobDie.feeList = feeList
      }
    },
    setCalc(state, val) {
      state.data = val
    },
    setMedicalDateRange(state, val) {
      console.log(val)
      state.medicalDateRange.push(val)
    },
    setMedicalDateRangeList(state, val) {
      state.medicalDateRange = val
    },
    setCalcAdvanceds(state, val) {
      state.data.advanceds.push(val)
    },
    setAdvancedList(state, val) {
      state.data.advanceds = val
    },
    // setPersonalArrear(state, val) {
    //   state.personalArrear.push(val)
    // },
    // setPersonalArrearList(state, val) {
    //   state.personalArrear = val
    // },
  },
  getters: {
    getAnnuityPersonal: state => {
      return state.annuity.personal
    },
    getAnnuityCompany: state => {
      return state.annuity.company
    },
    getAnnuityBasic: state => {
      return state.annuity.basic
    },
    getMedicalPayInFull: state => {
      return state.medical.payInFull
    },
    getAnnuityReturnOnJobDie: state => {
      return state.annuity.returnOnJobDie
    },
    getCalc: (state) => {
      return state.data
    },
    getMedicalDateRange: state => {
      return state.medicalDateRange
    },
    getCalcAdvanceds: (state) => {
      return state.data.advanceds
    },
    // getPersonalArrear: (state) => {
    //   return state.personalArrear
    // },
    

  }
}

export default calc