const Policy = (resolve) => require(["@/views/policy/PolicyListPage"], resolve);
const PolicyPage = (resolve) => require(["@/views/policy/PolicyPage"], resolve);
const PolicyCalc = (resolve) => require(["@/views/policy/calc/PolicyCalc"], resolve);
const PolicyProjectList = (resolve) => require(["@/views/policy/calc/ProjectList"], resolve);

const routers = [
	{
		path: "/policy",
		name: "Policy",
		component: Policy,
		meta: {
			auth: false
		}
	},
	{
		path: "/policyPage",
		name: "policyPage",
		component: PolicyPage,
		meta: {
			auth: false
		}
	},
  {
		path: "/policy-calc",
		name: "PolicyCalc",
		component: PolicyCalc,
		meta: {
			auth: false
		}
	},
	{
		path: "/policy-project",
		name: 'PolicyProjectList',
		component: PolicyProjectList,
		meta: {
			auth: false
		}
	}
];

export default routers;
