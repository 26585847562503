import crypto from './crypto'

export default {
    get: key => {
        let v = localStorage.getItem(key)
        if (v === null) {
            return null
        }

        let x = crypto.decrypt(v)
        return JSON.parse(x)
    },
    set: (key, value) => {
        localStorage.setItem(key, crypto.encrypt(value))
    },
    remove: key => {
        localStorage.removeItem(key)
    }
}