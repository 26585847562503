import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import toLogin from '@/utils/login'
import mineRouters from './modules/mine'
import albumRouters from './modules/album'
import articleRouters from './modules/article'
import qaRouters from './modules/qa'
import calcleRouters from './modules/calc'
import policy from './modules/policy'
import comment from './modules/comment'

const AuthPage = resolve => require(['@/views/AuthPage'], resolve)
const NotFoundPage = resolve => require(['@/views/NotFoundPage'], resolve)


Vue.use(Router)

// 部署在非根目录时需要，动态计算出第一个路径, 放在 router base 下面
// function getAbsolutePath () {
//     let path = location.pathname
//     returnpath.substring(0, path.lastIndexOf('/') + 1)
// }


const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: "/",
    name: "Home",
    meta: {
      title: "首页",
      keepAlive: true
    },
    component: () => import("@/views/home/IndexPage.vue"),
  },
  {
    path: "/login",
    name: "Login",
    meta: {
      title: "登录",
      keepAlive: true
    },
    component: () => import("../views/login/index.vue"),
  },
  ...articleRouters,
  ...albumRouters,
  ...mineRouters,
  ...qaRouters,
  ...calcleRouters,
  ...policy,
  ...comment,
  {
    path: '/auth/:url',
    name: 'Auth',
    meta: {
      title: " 加载中",
      keepAlive: true,
      auth: false
    },
    component: AuthPage
  }, {
    path: '*',
    name: 'NotFoundPage',
    meta: {
      auth: false
    },
    component: NotFoundPage
  }
];
const router = new Router({
  mode: 'history',
  routes
});

const userAgent = window.navigator.userAgent;
const inIOS = /iPhone|iPad|iPod/i.test(userAgent);

router.beforeEach((to, from, next) => {
  const { title, auth, refresh } = to.meta
  document.title = title || '河蟹星球'

  //history模式在ios上pushState无效,导致分享url地址不对
  if (inIOS && refresh && !localStorage.getItem("refresh")) {
    localStorage.setItem("refresh", "true")
    location.href = location.protocol + "//" + location.host + to.fullPath;
    return
  }
  localStorage.removeItem("refresh")
  if (process.env.NODE_ENV == 'development') {
    let res = {
      token: '51a4b55ee2cf4bdb8ffc63bc4b84c395',
      nickName: '测试测试测试测试测试',
      author: true,
      avatar: 'http://thirdwx.qlogo.cn/mmopen/rvUOAnMcoCrX1zJY0drEEJKZfDGojXoGYSTU6wJ9uSic4rOsbRXKDggULvTg4HVoGPyjVZlvTDZvgg77p44jfHJibfNKpwGiaq3/132'
    }
    store.commit('setUser', res)
  }

  const token = store.getters.token
  if (auth == undefined && !token) {
    console.log('toLogin', to.fullPath)
    return toLogin(to.fullPath)
  }

  next()
})

export default router
