const QuestionTab = resolve => require(['@/views/question/QuestionTabPage'], resolve)
const QuestionAnswer = resolve => require(['@/views/question/QuestionAnswerDetailPage'], resolve)
const ExpertList = resolve => require(['@/views/question/ExpertListPage'], resolve)
const Ask = resolve => require(['@/views/question/AskPage'], resolve)
const ReplyPage = resolve => require(['@/views/reply/ReplyPage'], resolve)

const routers = [{
  path: "/my-question-list",
  name: "QuestionTab",
  component: QuestionTab,
},{
  path: "/question",
  name: "QuestionAnswer",
  component: QuestionAnswer,
},{
  path: "/expert-list",
  name: "ExpertList",
  component: ExpertList,
},{
  path: "/ask",
  name: "Ask",
  component: Ask,
},{
  path: "/reply",
  name: "ReplyPage",
  component: ReplyPage,
}]

export default routers