const UserCommentsManagementPage = resolve => require(['@/views/album/UserCommentsManagementPage'], resolve)
const AuthorCommentsManagementPage = resolve => require(['@/views/album/AuthorCommentsManagementPage'], resolve)
const ArticleCommentDetailsPage = resolve => require(['@/views/album/ArticleCommentDetailsPage'], resolve)
const routers = [{
    path: "/my-comment-list",
    name: "UserCommentsManagementPage",
    component: UserCommentsManagementPage,
},
{
    path: "/my-article-comment-list",
    name: "AuthorCommentsManagementPage",
    component: AuthorCommentsManagementPage,
},
{
    path: "/article-comment-details",
    name: "ArticleCommentDetailsPage",
    component: ArticleCommentDetailsPage,
}]

export default routers