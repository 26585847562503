import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import { Toast } from 'vant'
// import Navigation from 'vue-navigation'

import './style/vant_reset.less'
import './style/common.css'

import { Lazyload } from 'vant';
import { dateFormat, yyyyMMFormat } from './filters'

import MtaH5 from 'mta-h5-analysis'

// 进入前，先把 session 中记录层级的数据清除掉
// window.sessionStorage.removeItem('VUE_NAVIGATION')

Vue.use(Lazyload);
Vue.filter('dateFormat', dateFormat)
Vue.filter('yyyyMMFormat', yyyyMMFormat)

// Toast.allowMultiple();
Vue.use(Toast)
// Vue.use(Navigation, {router, store, moduleName: 'scnav', keyName: 'xz'})


MtaH5.init({
    "sid":'500725488', //必填，统计用的appid
    "cid":'500693922',//如果开启自定义事件，此项目为必填，否则不填
    "autoReport":0,//是否开启自动上报(1:init完成则上报一次,0:使用pgv方法才上报)
    "senseHash":1, //hash锚点是否进入url统计
    "senseQuery":1, //url参数是否进入url统计
    "performanceMonitor":1,//是否开启性能监控
})
Vue.prototype.MtaH5 = MtaH5

// 拿到router实例的构造器
const routerCotr = router.constructor
// 在原型上维护一个routeStack，用来存储路由记录
routerCotr.prototype.routeStack = []
// 使用push的时候压栈（因为执行go返回时，实际上只需要用到routeStack的length，所以没有处理replace等方法的情况，也可以将routeStack换成整数）
routerCotr.prototype.push = new Proxy(routerCotr.prototype.push, {
    apply (target, obj, args) {
        MtaH5.pgv()
        routerCotr.prototype.routeStack.push(args[0])
        return Reflect.apply(target, obj, args)
    }
})

new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App)
})
