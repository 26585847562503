import Vue from 'vue'
import Vuex from 'vuex'
import store from '@/utils/storage'
import audio from './modules/audio'
import contract from './modules/contract'
import calc from './modules/calc'
import result from './modules/result'
import policy from './modules/policy'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    audio,
    contract,
    calc,
    result,
    policy
  },
  state: {
    userInfo: store.get('__x') || {}
  },
  mutations: {
    setUser(state, val) {
      state.userInfo = val
      store.set('__x', JSON.stringify(val))
    },
    clearUser(state) {
      state.userInfo = {}
      store.remove('__x')
    }
  },
  getters: {
    token: ({ userInfo }) => { return userInfo.token },
    userInfo: ({ userInfo }) =>  { return userInfo }
  }
})
