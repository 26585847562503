import rules from '../../data/policy/Rules.js'
const policy = {
	namespaced: true,
	state: {
		reload: true, // 指示是否在加载算一算首页
		project: new Set(), // 满足的项目编号
		projectNum: 0, // 满足的项目数量
		showDetailQ: false,
		totalFee: 0, // 总金额
		rsjRatio: 0, // 人社局补贴比列
		rsjFee: 0,
		kwRatio: 0, // 科委补贴比例
		kwFee: 0,
		jxwRatio: 0, // 经信委补贴比例
		jxwFee: 0,
		zxqyjRatio: 0, // 中小企业局补贴比例
		zxqyjFee: 0,
		qtRatio: 0, // 其他补贴比例
		qtFee: 0,
		isCalc: false,
		questionProjectMap: new Map(),
		projectHash: new Map(),
		projectTmp: new Map(),
	},
	mutations: {
		setReload(state, val) {
			state.reload = val;
		},
		setProjectSet(state, arrayVal) {
			arrayVal.forEach((item) => {
				state.project.add(item);
			});
		},
		setProjectNum(state, num) {
			state.projectNum = num;
		},
		addProject(state, pid) {
			let num = state.projectTmp.get(pid);
			if (num == undefined) {
				state.projectTmp.set(pid, 1);
				++state.projectNum;
				state.project.add(pid);
			} else {
				++num;
				state.projectTmp.set(pid, num);
			}

			// if (!state.project.has(pid)) {
			// 	state.project.add(pid)
			// 	++state.projectNum
			// }
		},
		deleteProject(state, pid) {
			let num = state.projectTmp.get(pid);
			if (num != undefined) {
				--num;
				if (num == 0) {
					state.projectTmp.delete(pid);

					--state.projectNum;
					state.project.add(pid);
				} else {
					state.projectTmp.set(pid, num);
				}
			}

			// if (state.project.delete(pid)) {
			//      		--state.projectNum
			//    		}
		},
		showDetail(state) {
			state.showDetailQ = true;
			this.commit("policy/updateRatio");
		},
		updateTotalFee(state, value) {
			state.totalFee += value;
			this.commit("policy/updateRatio");
		},
		updateRSJFee(state, value) {
			state.rsjFee += value;
			this.commit("policy/updateRatio");
		},
		updateKWFee(state, value) {
			state.kwFee += value;
			this.commit("policy/updateRatio");
		},
		updateJXWFee(state, value) {
			state.jxwFee += value;
			this.commit("policy/updateRatio");
		},
		updateZXQYJFee(state, value) {
			state.zxqyjFee += value;
			this.commit("policy/updateRatio");
		},
		updateQTFee(state, value) {
			state.qtFee += value;
			this.commit("policy/updateRatio");
		},
		updateRatio(state) {
      if (state.totalFee != 0) {
				state.rsjRatio = Math.round(((state.rsjFee * 100) / state.totalFee) * Math.pow(10, 2)) / Math.pow(10, 2);
				state.kwRatio = Math.round(((state.kwFee * 100) / state.totalFee) * Math.pow(10, 2)) / Math.pow(10, 2);
				state.jxwRatio = Math.round(((state.jxwFee * 100) / state.totalFee) * Math.pow(10, 2)) / Math.pow(10, 2);
				state.zxqyjRatio = Math.round(((state.zxqyjFee * 100) / state.totalFee) * Math.pow(10, 2)) / Math.pow(10, 2);
				state.qtRatio = Math.round(((state.qtFee * 100) / state.totalFee) * Math.pow(10, 2)) / Math.pow(10, 2);
			}
		},
		mapQuestionProject(state) {
			if (state.isCalc) {
				return;
			}

			state.isCalc = true;
			for (let rule of rules) {
				state.projectHash.set(rule.id, rule);
				for (let condition of rule.conditions) {
					for (let regulation of condition.regulations) {
						let q = state.questionProjectMap.get(regulation.qid);
						if (q == undefined) {
							q = new Set();
							state.questionProjectMap.set(regulation.qid, q);
						}
						q.add(rule);
					}
				}
			}
		},
	},
};

export default policy;
