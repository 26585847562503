function parseQuery() {
    const res = {};

    const query = (location.href.split("?")[1] || "")
        .trim()
        .replace(/^(\?|#|&)/, "");

    if (!query) {
        return res;
    }

    query.split("&").forEach(param => {
        const parts = param.replace(/\+/g, " ").split("=");
        const key = decodeURIComponent(parts.shift());
        const val = parts.length > 0 ? decodeURIComponent(parts.join("=")) : null;

        if (res[key] === undefined) {
            res[key] = val;
        } else if (Array.isArray(res[key])) {
            res[key].push(val);
        } else {
            res[key] = [res[key], val];
        }
    });

    return res;
}

export function oAuth(backUrl) {
    const { code } = parseQuery()
    if (!code) return toAuth(backUrl)
}

function toAuth(backUrl) {
    location.href = getAuthUrl('wx1ca85e39f2fb3fca', backUrl)
}

function getAuthUrl(appId, backUrl) {
    const redirect_uri = encodeURIComponent(
        `${location.origin}/auth/` +
        encodeURIComponent(
            encodeURIComponent(backUrl))
    )

    const state = encodeURIComponent(
        ("" + Math.random()).split(".")[1] + "authorizestate"
    )

    return `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=${state}#wechat_redirect`;
}