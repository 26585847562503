export const formatTime = interval =>{
    interval = interval | 0;
    const minute = interval / 60 | 0;
    const second = padZero(interval % 60);
    return `${minute}:${second}`
}

/*秒前边加0*/
function padZero(num, n = 2) {
    let len = num.toString().length;
    while (len < n) {
        num = '0' + num;
        len++
    }

    return num
}

export const LOGO_URL = 'http://scfile.soucee.com/file/static_img/logo-hx-small.jpg'
export const SLOGAN = '河蟹星球-人力资源管理公益性互动学习平台'

export const MIN_WAGE = 3282
export const MAX_WAGE = 16407