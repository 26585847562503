const Article = resolve => require(['@/views/album/ArticlePage'], resolve)
const CommentPage = resolve => require(['@/views/album/CommentPage'], resolve)

const routers = [{
  path: "/article",
  name: "Article",
  component: Article,
},{
  path: "/comment-page",
  name: "CommentPage",
  component: CommentPage,
}]

export default routers