let rules = [
	{
		id: 'f88511b54bea4267a98efc5f316262f0',
		title: '稳岗补贴',
		catalog: 'rsj',
		catalog_name: '人社局',
		enterprise: 20,
		data_range: '2015/10-2022/10',
		url: '/document',
		documentId: '4db6141cc0c649cdae2d260f1eb5248c',
		conditions_type: 's/m', // s-只能满足一次，m-满足多次
		conditions: [
			{
				type:'and',
				is_satisfied: false,
				calc_rule: 'null',
				regulations:[
					{
						qid: 'B4',
						is_satisfied: false,
						values: [
							{opt: '', calc_rule: '3664*0.5*{value}/100/10000'}
						]
					}
				]
			}
		]
	},
	{
		id: '388bc5ca098c41ebbf0a5b3fdca8d9d1',
		title: '返乡创业重点企业贴息',
		catalog: 'rsj',
		catalog_name: '人社局',
		enterprise: 23,
		data_range: '2017/10-2022/10',
		url: '/document',
		documentId: 'b35ed1d989264c8795ff009a0e1dea73',
		conditions_type: 's/m',
		conditions: [
			{
				type: 'and',
				is_satisfied: false,
				calc_rule: 'null',
				regulations: [
					{
						qid: 'B5',
						is_satisfied: false,
						values: [
							{opt: '', calc_rule: '0'}
						]
					}
				]
			}
		]
	},
	{
		id: 'ffe1ec52d5194218bc8afa29944113d7',
		title: '岗前培训',
		catalog: 'rsj',
		catalog_name: '人社局',
		enterprise: 26,
		data_range: '2017/10-2022/10',
		url: '/document',
		documentId: '0c300d1bc1d94ffb87ca943fbb6c8e01',
		conditions_type: 's/m',
		conditions: [
			{
				type: 'and',
				is_satisfied: false,
				calc_rule: 'null',
				regulations: [
					{
						qid: 'B5',
						is_satisfied: false,
						values: [
							{opt: '', calc_rule: '{value}*400/10000'}
						]
					}
				]
			},
			{
				type: 'and',
				is_satisfied: false,
				calc_rule: 'null',
				regulations: [
					{
						qid: 'B6',
						is_satisfied: false,
						values: [
							{opt: '', calc_rule: '{value}*400/10000'}
						]
					}
				]
			}
		]
	},
	{
		id: '92a354958a0346ca91b95020ccb325ca',
		title: '大学生就业见习补贴',
		catalog: 'rsj',
		catalog_name: '人社局',
		enterprise: 26,
		data_range: '2017/10-2022/10',
		url: '/document',
		documentId: 'f90fe5692fbe45d9b6faa123140e7875',
		conditions_type: 's/m',
		conditions: [
			{
				type: 'and',
				is_satisfied: false,
				calc_rule: 'null',
				regulations: [
					{
						qid: 'B6',
						is_satisfied: false,
						values: [
							{opt: '', calc_rule: '{value}*7200/10000'}
						]
					}
				]
			}
		]
	},
	{
		id: '2b92240eac0d4d66996ce6da50fead06',
		title: '成长型微型企业培育库',
		catalog: 'zxqyj',
		catalog_name: '中小企业局',
		enterprise: 26,
		data_range: '2017/10-2022/10',
		conditions_type: 's/m',
		url: '/document',
		documentId: '98ca714cbf534be1940da01019bb4402',
		conditions: [
			{
				type: 'and',
				is_satisfied: false,
				calc_rule: '5',
				regulations: [
					{
						qid: 'B3',
						is_satisfied: false,
						values: [
							{opt: 'B3-3', calc_rule: 'null'},
							{opt: 'B3-4', calc_rule: 'null'},
							{opt: 'B3-5', calc_rule: 'null'},
							{opt: 'B3-6', calc_rule: 'null'},
							{opt: 'B3-7', calc_rule: 'null'}
						]
					}
				]
			}
		]
	},
	{
		id: '2b92240eac0d4d66996ce6da50fead05',
		title: '“专精特新”中小企业奖励',
		catalog: 'zxqyj',
		catalog_name: '中小企业局',
		enterprise: 26,
		data_range: '2017/10-2022/10',
		conditions_type: 's',
		url: '/document',
		documentId: '451102e5c5094b30b602059d0910cb37',
		conditions: [
			{
				type: 'and',
				is_satisfied: false,
				calc_rule: '30', // 专精特新
				regulations: [
					{
						qid: 'D1',
						is_satisfied: false,
						values: [
							{opt: 'D1-D11', calc_rule: 'null'}
						]
					},
					{
						qid: 'D2',
						is_satisfied: false,
						values: [
							{opt: 'D2-D21', calc_rule: 'null'}
						]
					},
					{
						qid: 'D3',
						is_satisfied: false,
						values: [
							{opt: 'D3-D32', calc_rule: 'null'}
						]
					}
				]
			},
			{
				type: 'and',
				is_satisfied: false,
				calc_rule: '60', // 小巨人
				regulations: [
					{
						qid: 'D1',
						is_satisfied: false,
						values: [
							{opt: 'D1-D11', calc_rule: 'null'}
						]
					},
					{
						qid: 'D2',
						is_satisfied: false,
						values: [
							{opt: 'D2-D21', calc_rule: 'null'}
						]
					},
					{
						qid: 'D3',
						is_satisfied: false,
						values: [
							{opt: 'D3-D33', calc_rule: 'null'}
						]
					}
				]
			},
			{
				type: 'and',
				is_satisfied: false,
				calc_rule: '200', // 隐形冠军
				regulations: [
					{
						qid: 'D1',
						is_satisfied: false,
						values: [
							{opt: 'D1-D11', calc_rule: 'null'}
						]
					},
					{
						qid: 'D2',
						is_satisfied: false,
						values: [
							{opt: 'D2-D21', calc_rule: 'null'}
						]
					},
					{
						qid: 'D3',
						is_satisfied: false,
						values: [
							{opt: 'D3-D34', calc_rule: 'null'}
						]
					}
				]
			}
		]
	},
	{
		id: 'ec5986a433fe4f22beb34d34fe767657',
		title: '中小微企业股改奖励',
		catalog: 'zxqyj',
		catalog_name: '中小企业局',
		enterprise: 26,
		data_range: '2017/10-2022/10',
		conditions_type: 's',
		url: '/document',
		documentId: '036451acb2df4e5fb5a6d5e34b2f066c',
		conditions: [
			{
				type: 'and',
				is_satisfied: false,
				calc_rule: '4',
				regulations: [
					{
						qid: 'D4',
						is_satisfied: false,
						values: [
							{opt: 'D4-D42', calc_rule: 'null'}
						]
					}
				]
			}
		]
	},
	{
		id: '4cfef720bd52473b9b1f325f8b5a830a',
		title: '中小微企业股改挂牌奖励',
		catalog: 'zxqyj',
		catalog_name: '中小企业局',
		enterprise: 26,
		data_range: '2017/10-2022/10',
		conditions_type: 's',
		url: '/document',
		documentId: '036451acb2df4e5fb5a6d5e34b2f066c',
		conditions: [
			{
				type: 'and',
				is_satisfied: false,
				calc_rule: '3',
				regulations: [
					{
						qid: 'D5',
						is_satisfied: false,
						values: [
							{opt: 'D5-D52', calc_rule: 'null'}
						]
					}
				]
			},
			{
				type: 'and',
				is_satisfied: false,
				calc_rule: '25',
				regulations: [
					{
						qid: 'D5',
						is_satisfied: false,
						values: [
							{opt: 'D5-D53', calc_rule: 'null'}
						]
					}
				]
			},
			{
				type: 'and',
				is_satisfied: false,
				calc_rule: '30',
				regulations: [
					{
						qid: 'D5',
						is_satisfied: false,
						values: [
							{opt: 'D5-D54', calc_rule: 'null'}
						]
					}
				]
			}
		]
	}, //
	{
		id: '4e3c1d7ae8ce486999104c6652bcaff9',
		title: '重庆市科技型企业入库',
		catalog: 'kw',
		catalog_name: '科委',
		enterprise: 43,
		data_range: '2017/10-2022/10',
		conditions_type: 's',
		url: '/document',
		documentId: 'f921d26583cb4949b88dede59834fbfd',
		conditions: [
			{
				type: 'and',
				is_satisfied: false,
				calc_rule: '3',
				regulations: [
					{
						qid: 'B3',
						is_satisfied: false,
						values: [
							{opt:'B3-2', calc_rule: 'null'},
							{opt:'B3-3', calc_rule: 'null'},
							{opt:'B3-4', calc_rule: 'null'},
							{opt:'B3-5', calc_rule: 'null'},
							{opt:'B3-6', calc_rule: 'null'},
							{opt:'B3-7', calc_rule: 'null'}
						]
					}
				]
			}
		]
	},
	{
		id: '500f1bd1cde34a8fb96753018c558892',
		title: '高新技术企业认定',
		catalog: 'kw',
		catalog_name: '科委',
		enterprise: 46,
		data_range: '2016/01-2022/10',
		conditions_type: 's',
		url: '/document',
		documentId: '8ae7b23ed455454391f45519290531eb',
		conditions: [
			{
				type: 'and',
				is_satisfied: false,
				calc_rule: '30',
				regulations: [
					{
						qid: 'D6',
						is_satisfied: false,
						values: [
							{opt: 'D6-D61', calc_rule: 'null'}
						]
					},
					{
						qid: 'D7',
						is_satisfied: false,
						values: [
							{opt: 'D7-D71', calc_rule: 'null'}
						]
					},
					{
						qid: 'D8',
						is_satisfied: false,
						values: [
							{opt: 'D8-D81', calc_rule: 'null'}
						]
					}
				]
			}
		]
	},
	{
		id: '81d8b062b3a548bd9f3dc70a6dd3a881',
		title: '重庆市高新技术产品认定',
		catalog: 'kw',
		catalog_name: '科委',
		enterprise: 33,
		data_range: '2017/05-2022/10',
		conditions_type: 's',
		url: '/document',
		documentId: '78723abb389e4d05a4ee46dffb60bb11',
		conditions: [
			{
				type: 'and',
				is_satisfied: false,
				calc_rule: '10',
				regulations: [
					{
						qid: 'D9',
						is_satisfied: false,
						values: [
							{opt: 'D9-D91', calc_rule: 'null'}
						]
					},
					{
						qid: 'D10',
						is_satisfied: false,
						values: [
							{opt: 'D10-D101', calc_rule: 'null'}
						]
					}
				]
			}
		]
	},
	{
		id: '75bff236702e474d9a23be96feee1f7e',
		title: '知识产权贯标',
		catalog: 'qt',
		catalog_name: '其他',
		enterprise: 16,
		data_range: '2017/10-2022/10',
		conditions_type: 's',
		url: '/document',
		documentId: '3099ac765897451383b3db76cc4df1ad',
		conditions: [
			{
				type: 'and',
				is_satisfied: false,
				calc_rule: '10',
				regulations: [
					{
						qid: 'D11',
						is_satisfied: false,
						values: [
							{opt: 'D11-D111', calc_rule: 'null'},
						]
					}
				]
			}
		]
	},
	{
		id: 'b45ac6e31461420bbedd21b9960ae721',
		title: '电子商务示范企业补贴',
		catalog: 'qt',
		catalog_name: '其他',
		enterprise: 6,
		data_range: '2017/10-2022/10',
		conditions_type: 's',
		url: '/document',
		documentId: 'b45ac6e31461420bbedd21b9960ae721',
		conditions: [
			{
				type: 'and',
				is_satisfied: false,
				calc_rule: '30',
				regulations: [
					{
						qid: 'D12',
						is_satisfied: false,
						values: [
							{opt: 'D12-D121', calc_rule: 'null'}
						]
					}
				]
			},
			{
				type: 'and',
				is_satisfied: false,
				calc_rule: '10',
				regulations: [
					{
						qid: 'D12',
						is_satisfied: false,
						values: [
							{opt: 'D12-D122', calc_rule: 'null'}
						]
					}
				]
			}

		]
	},
	{
		id: 'dde4f144a95447f1ba0a3774784b46dd',
		title: '困难补贴',
		catalog: 'rsj',
		catalog_name: '人社局',
		enterprise: 65,
		data_range: '2017/10-2019/04',
		url: '/document',
		documentId: 'ffeab3022b4245f8b5fabeb192451157',
		conditions_type: 's',
		conditions: [
			{
				type: 'and',
				is_satisfied: false,
				calc_rule: 'null',
				regulations: [
					{
						qid: 'B4',
						is_satisfied: false,
						values: [
							{opt: '', calc_rule: '(3664-1500)*30.1*12*{value}/100/10000'}
						]
					}
				]
			}
		]
	},
	{
		id: '75acfb6cb2e4422d8cbd027decb56c13',
		title: '社保补贴',
		catalog: 'rsj',
		catalog_name: '人社局',
		enterprise: 65,
		data_range: '2017/10-2019/04',
		url: '/document',
		documentId: '6f1b91eabd37435bb3a8730737d0ce64',
		conditions_type: 's',
		conditions: [
			{
				type: 'and',
				is_satisfied: false,
				calc_rule: 'null',
				regulations: [
					{
						qid: 'B4',
						is_satisfied: false,
						values: [
							{opt: '', calc_rule: '3664*29.9*12*{value}/100/10000/10' }
						]
					}
				]
			}
		]
	},
	{
		id: 'cdf1e46f869841f99bdfc8e1348885c7',
		title: '小微补贴',
		catalog: 'rsj',
		catalog_name: '人社局',
		enterprise: 65,
		data_range: '2017/10-2019/04',
		url: '/document',
		documentId: '7cfed82df7904916a42b61e597c6ee46',
		conditions_type: 's',
		conditions: [
			{
				type: 'and',
				is_satisfied: false,
				calc_rule: 'null',
				regulations: [
					{
						qid: 'B4',
						is_satisfied: false,
						values: [
							{opt: '', calc_rule: '3664*7*12*{value}/100/10000' }
						]
					}
				]
			}
		]
	}
]


export default rules;