import { formatTime } from '@/utils/misc'

const audio = {
    namespaced: true,
    state: {
        audio: {},
        currentTime: 0,
        duration: 0,
        status: 'stop',
        loading: false,
        playList: [],
        curIdx: -1
    },
    mutations: {
        setAudio(state, val) {
            state.audio = val
        },
        setStatus(state, val) {
            state.status = val
            val === 'playing' ? state.audio.play() : state.audio.pause()

            if (val === 'stop' || val === 'end' || val === 'error') {
                state.audio.currentTime = 0
            }
        },
        play(state, val) {
            if (val < 0 || val > state.playList.length) {
                return
            }

            state.curIdx = val
            state.status = 'playing'
            state.audio.src = state.playList[val].audioUrl
            state.audio.currentTime = 0
            //'https://asset.qiaobutang.com/assessment/audio/24.%E4%BB%A5%E7%BB%88%E4%B8%BA%E5%A7%8B%EF%BC%8C%E4%BB%8E%E7%AE%80%E5%8E%86%E5%80%92%E6%8E%A8%E8%81%8C%E4%B8%9A%E7%94%9F%E6%B6%AF%E8%A7%84%E5%88%92.m4a'
            state.audio.play()
        },
        setCurTime(state, val) {
            state.currentTime = val
        },
        setDuration(state, val) {
            state.duration = val
        },
        setPercent({ audio }, val) {
            audio.currentTime = audio.duration * val
        },
        setPlayList(state, val) {
            state.playList = val
            state.status = 'stop'
        },
        setLoading(state, val) {
            state.loading = val
        }
    },
    getters: {
        status: ({ status }) => {
            return status
        },
        percent: ({ currentTime, duration }) => {
            return currentTime / duration
        },
        curShowtime: ({ currentTime }) => {
            return formatTime(currentTime)
        },
        totalShowTime: ({ duration }) => {
            return formatTime(duration)
        },
        curInfo: ({ playList, curIdx }) => {
            if (curIdx == -1) return null
            return playList[curIdx]
        }
    }
}

export default audio