<template>
	<div style="height: 100%">
		<audio
			ref="audio"
			@loadstart="audioLoadStart"
			@canplay="audioCanPlay"
			@canplaythrough="audioCanPlayThrough"
			@error="audioError"
			@abort="audioError"
			@timeupdate="audioUpdateTime"
			@ended="audioEnd"
		></audio>
		<router-view></router-view>
		<!-- <navigation> -->
		<!-- </navigation> -->
	</div>
</template>

<script>
import Vue from "vue";
import { mapMutations } from "vuex";
import { NoticeBar } from "vant";
import {} from "vant";

export default {
	name: "App",
	components: {
		[NoticeBar.name]: NoticeBar,
	},
	data() {
		return {
			audio: null,
		};
	},
	created() {
		Vue.prototype.$showLoading = (message = "加载中...") => {
			this.$toast.loading({
				mask: true,
				message,
				position: "center",
				duration: 0,
			});
		};

		Vue.prototype.$hideLoading = () => {
			this.$toast.clear();
		};

		Vue.prototype.$showSuccess = (message, onClose) => {
			this.$toast.success({
				mask: true,
				message,
				position: "center",
				duration: 1000,
				onClose,
			});
		};

		Vue.prototype.$showInfo = (message, onClose) => {
			this.$toast({
				message,
				position: "center",
				duration: 1000,
				onClose,
			});
		};

		Vue.prototype.$showFail = (message, onClose) => {
			this.$toast.fail({
				mask: true,
				message,
				position: "center",
				duration: 1000,
				onClose,
			});
		};
	},
	mounted() {
		this.audio = this.$refs.audio;
		this.setAudio(this.audio);

		// 不能在每个页面中监听物理返回键，因为 vue-navigation 缓存了页面实例
		// 当页面退出是，不会调用 destoryed 方法
		// 只能在最外层页面进行监听
		if (window.history && window.history.pushState) {
			history.pushState(null, null, document.URL);
			window.addEventListener("popstate", this.goBack, false);
		}

		document.addEventListener(
			"WeixinJSBridgeReady",
			() => {
				console.log("weixin js bridge ready");
				this.audio.play();
			},
			false
		);
	},
	destroyed() {
		window.removeEventListener("popstate", this.goBack, false);
	},
	// computed: {
	//     ...mapState('audio', {
	//         audioSrc: state => {
	//             const xx = state.audio.src || 'https://asset.qiaobutang.com/assessment/audio/24.%E4%BB%A5%E7%BB%88%E4%B8%BA%E5%A7%8B%EF%BC%8C%E4%BB%8E%E7%AE%80%E5%8E%86%E5%80%92%E6%8E%A8%E8%81%8C%E4%B8%9A%E7%94%9F%E6%B6%AF%E8%A7%84%E5%88%92.m4a'
	//             console.log(state.audio)
	//             return xx
	//         }
	//     })
	// },
	methods: {
		goBack() {
			// 这里强依赖于 vue-navigation 记录路由顺序的数据
			const routes = JSON.parse(window.sessionStorage.VUE_NAVIGATION || "[]");
			if (routes.length === 1) {
				this.$router.replace("/");
			}
			// 下面的代码不靠谱
			// 特别是通过windows.history.length 来判断是否是最后一个页面
			// 首次进入 history.length 为 3
			// 同一页面，不停的刷新浏览器，history.length 也会增加
			// 没搞清楚为什么进来history是 3
			// console.log(window.history.length)
			// if (window.history.length <= 3) {
			//     this.$router.replace('/')
			// }
			// 被 vue-navigation接管，不要自己返回
			// else {
			//     this.$router.go(-1)
			// }
		},
		audioLoadStart() {
			console.log("audio load start");
			this.setLoading(true);
		},
		audioCanPlay(e) {
			console.log("audio can play", e.target.duration);
			this.setDuration(e.target.duration);
		},
		audioCanPlayThrough() {
			console.log("audio can play through");
			this.setLoading(false);
		},
		audioEnd() {
			this.setStatus("end");
			console.log("audio end");
		},
		audioError(e) {
			console.log("xxxxxxxxxxxxxxxxxxxxxxxxxxxx,audio error:", e);
		},
		audioUpdateTime(e) {
			this.setCurTime(e.target.currentTime);
		},
		...mapMutations("audio", ["setAudio", "setStatus", "setCurTime", "setDuration", "setLoading"]),
	},
};
</script>

<style>
::-webkit-scrollbar {
	display: none;
}
.img {
	-webkit-touch-callout: none;
}
</style>
