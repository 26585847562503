const result = {
    namespaced: true,
    state: {
        data: {
            annuity: '',
            feesYear: '',
            paymentBase: '',
            basicAnnuity: '',
            transitionAnnuity: '',
            individualAnnuity: '',
            adjustAnnuity: '',
            otherAnnuity: '',
            retireDate: '',
            averageSalary: ''
        }
    },
    mutations: {
        setResult(state, val) {
            state.data = val
        }
    },
    getters: {
        getResult: ( state ) => {
            return state.data;
        },
    }
}

export default result